<template>
    <div>
        <iframe
            :src="embeddedLink"
            width="100%"
            height="500"
            frameborder="0"
        />
    </div>
</template>

<script>
export default {
    name: 'PdfView',
    props: {
        url: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {
            embeddedLink: '',
        };
    },
    computed: {
    },
    watch: {
        url() {
            this.generateEmbeddedLink();
        },
    },
    mounted() {
    },
    methods: {
        generateEmbeddedLink() {
            const link = this.url.trim();
            const idRegex = /\/d\/(.+?)(\/|$)/;
            const viewRegex = /\/file\/d\/(.+?)\/view/;
            const [, fileId] = idRegex.exec(link) || [];
            const [, viewFileId] = viewRegex.exec(link) || [];
            const embeddedId = fileId || viewFileId;
            if (link.includes('drive.google.com')) {
                this.embeddedLink = `https://drive.google.com/file/d/${embeddedId}/preview`;
            } else {
                this.embeddedLink = link;
            }
        },
    },
};
</script>

<style>
#adobe-dc-view{
  width: 80vw;
  height: 60vw;
  max-width: 900px;
  max-height: 675px;
}

@media screen and (max-width: 960px) {
  #adobe-dc-view{
    width: 92vw;
    height: 69vw;
  }
}
</style>
